import MuxPlayer from '@mux/mux-player-react';
import MuxAudio from '@mux/mux-audio-react';

import { useEffect, useState } from 'react';
import "./Stream.css";
import "./Audio.css";
// import ReactAudioPlayer from 'react-audio-player';

import sleeping_duck from "./assets/sleeping_duck.jpeg";
import axios from 'axios';


export const AudioScreen = () => {
    const PLAYBACK_ID = "IS02CBAs5yhQmaWfHe029EE26XOiWErbhjqH6j016NTpYI";
    return (
        <div className="Audio-screen">
            <div className="Audio-player">
                <MuxAudio
                    playbackId={PLAYBACK_ID}
                    controls={true}
                    streamType="live"
                />
            </div>
            <div className="Audio-chat">
                <iframe
                    title="chatbox"
                    className="Audio-chat-iframe"
                    src="https://duckstadradio.ishoutbox.com"
                    allowTransparency={true}>
                </iframe>
            </div>
        </div>
    )
}

export const VideoScreen = () => {
    const PLAYBACK_ID = "IS02CBAs5yhQmaWfHe029EE26XOiWErbhjqH6j016NTpYI";
    const URL = "https://stream.mux.com/" + PLAYBACK_ID + ".m3u8";

    const [live, setLive] = useState(false);

    useEffect(() => {
        axios.get(URL).then(_ => {
            setLive(true);
        }).catch(err => {
            console.log(err);
            setLive(false);
        });
    });

    return (
        <div className="Stream-screen">
            {live
                ? <MuxPlayer playbackId={PLAYBACK_ID} streamType="live" />
                : <img className="Stream-slate" src={sleeping_duck} alt="sleeping-duck"/>
            }
        </div>
    )
};
