import { useState, useEffect } from "react";
import * as duckfile from "../assets/ducksofthemonth.json"
import * as streamfile from "../assets/streamdata.json"
import * as schedulefile from "../assets/schedule.json"

interface DuckOfTheMonth {
    month: string,
    name: string,
    eng_name: string,
    latin_name: string,
    pic_url: string
}

interface StreamData {
    date: string
    description: string,
    shows: ShowData[]
}

interface ShowData {
    title: string,
    description?: string,
    sc_url?: string,
    img_url: string,
}

interface ScheduleItem {
    day: string,
    date: string
  }


const useDuckofthemonth = () => {
    const [duck, setDuck] = useState<undefined | DuckOfTheMonth>(undefined);
    const duckfromfile = duckfile

    const getDuckOfTheMonth = async () => {
        const duck = duckfromfile.ducks[0]
        
        setDuck(duck);
    };

    useEffect(() => {
        if (duck === undefined) {
            getDuckOfTheMonth();
        }
    });

    return duck;
};

const useStreams= () => {
    const [streams, setStreams] = useState<undefined | StreamData[]>(undefined);
    const streamsfromfile = streamfile

    const getStreams = async () => {
        const streams: StreamData[] = streamsfromfile.streams
        setStreams(streams);
    };

    useEffect(() => {
        if (streams === undefined) {
            getStreams();
        }
    });

    return streams;
};

const useSchedule= () => {
    const [schedule, setSchedule] = useState<undefined | ScheduleItem[]>(undefined);
    const schedulesfromfile = schedulefile

    const getSchedule = async () => {
        const schedule: ScheduleItem[] = schedulesfromfile.schedule
        setSchedule(schedule);
    };

    useEffect(() => {
        if (schedule === undefined) {
            getSchedule();
        }
    });

    return schedule;
};

export { useDuckofthemonth, useStreams, useSchedule };
