import { useStreams } from "./providers/providers"

interface StreamData {
    date: string
    description: string,
    shows: ShowData[]
}

interface ShowData {
    title: string,
    description?: string
    sc_url?: string,
    img_url: string,
}

export const RecordingsScreen = () => {
    const streams = useStreams()

    return (
        <div className="Recordings-screen">
            {streams?.map((stream: StreamData) => <StreamComponent {...stream} />)}
        </div>
    )
}

const StreamComponent = ({ description, date, shows }: StreamData) => {
    return (
        <div className="Recordings-stream">
            <div className="Recordings-stream-title">{date}</div>
            <div className="Recordings-stream-content">
                {shows.map((show) => {
                    return (
                        <div className="Recordings-mix">
                            <a style={{width: "100%"}} href={show.sc_url}>
                                <img className="Recordings-mix-img" src={show.img_url} alt="img"></img>
                            </a>
                            {/* <div className="Recordings-mix-title">{mix.title}</div> */}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
